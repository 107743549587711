import {ChangeDetectionStrategy, Component, input, Signal} from '@angular/core'

@Component({
  selector: 'cft-wave-cover-image',
  templateUrl: './wave-cover-image.component.html',
  styleUrls: ['./wave-cover-image.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaveCoverImageComponent {
  /**
   * When true, this component width will be max screen-2xl (from tailwind config) and the waves will take 35%
   * When false, width will be unrestricted and the waves will take all the space available
   */
  fullWidth = input(false)
  colour: Signal<'blue' | 'grey'> = input('blue')
  reducedCoverTransparency = input(false)

  //Utility property - just the inverse of fullWidth for easier usage in template
  get fitLayout() {
    return this.fullWidth()
  }

  get waveFrontSrc(): string {
    switch (this.colour()) {
      case 'blue':
        return 'assets/images/wave_front.svg'
      case 'grey':
        return 'assets/images/wave_grey_front.svg'
      default:
        throw Error('Unknown colour')
    }
  }

  get waveBackSrc(): string {
    switch (this.colour()) {
      case 'blue':
        return 'assets/images/wave_back.svg'
      case 'grey':
        return 'assets/images/wave_grey_back.svg'
      default:
        throw Error('Unknown colour')
    }
  }
}

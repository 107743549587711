import {NgModule} from '@angular/core'
import {WaveCoverImageComponent} from './wave-cover-image.component'
import {NgClass, NgIf} from '@angular/common'

@NgModule({
  exports: [WaveCoverImageComponent],
  declarations: [WaveCoverImageComponent],
  imports: [NgIf, NgClass],
})
export class WaveCoverImageModule {}

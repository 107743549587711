@if (fitLayout) {
  <div
    class="wave-left-gutter-solid-fill"
    [class.bg-primary]="colour() === 'blue'"
    [class.bg-[#DEE2E5]]="colour() === 'grey'"
  ></div>
}
<div class="relative m-auto flex h-full w-full" [class.max-w-screen-2xl]="fitLayout">
  <div
    class="z-10"
    [class.bg-primary]="colour() === 'blue'"
    [class.bg-[#DEE2E5]]="colour() === 'grey'"
    [class.md:w-[35%]]="fitLayout"
  >
    <img
      class="wave-position absolute left-0 h-full object-cover"
      [class.md:left-[35%]]="fitLayout"
      [src]="waveFrontSrc"
      alt=""
    />
    <img
      class="wave-position absolute left-0 h-full object-cover"
      [class.md:left-[35%]]="fitLayout"
      [src]="waveBackSrc"
      alt=""
    />
  </div>
  <div class="h-full w-full flex-1 overflow-hidden" #image>
    <ng-content select="[content=background-image]"></ng-content>
  </div>
  @if (image.children.length) {
    <div class="w-25% linear-gradient-white absolute bottom-0 left-[75%] right-0 top-0 hidden md:block"></div>
  }
</div>
@if (colour() === 'blue') {
  <div
    class="linear-gradient-blue absolute bottom-0 left-0 right-0 top-0"
    [ngClass]="{
      'opacity-80': reducedCoverTransparency()
    }"
  ></div>
}
